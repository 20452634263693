import React from 'react'
import { navigate } from 'gatsby'
import Recaptcha from 'react-google-recaptcha'

const RECAPTCHA_KEY =
  process.env.NODE_ENV === 'development'
    ? '6LcGHX0UAAAAADnj-0zo3wsKtGA8TgzEWA0VLGXz'
    : process.env.SITE_RECAPTCHA_KEY

function encode (data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[ key ]))
    .join('&')
}

export default class Contact extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [ e.target.name ]: e.target.value })
  };

  handleMessageChange = e => {
    this.setState({ message: e.target.value, messageStatus: false })
  };

  handleRecaptcha = value => {
    this.setState({ 'g-recaptcha-response': value, recaptchaStatus: '' })
  };

  handleSubmit = e => {
    e.preventDefault()
    if (!this.state[ 'g-recaptcha-response' ]) {
      this.setState({ recaptchaStatus: 'recaptcha-danger' })
    } else if (!this.state.message) {
      this.setState({ messageStatus: true })
    } else {
      const form = e.target
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state
        })
      })
        .then(() => {
          navigate(form.getAttribute('action'))
        })
        .catch(err => console.error(err))
    }
  };

  render () {
    return (
      <form
        className="form"
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-recaptcha="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        <noscript>
          <p>This form won’t work with Javascript disabled</p>
        </noscript>
        <div className="field">
          <label className="label" htmlFor="name">
            Full Name
            <div className="control">
              <input
                id="name"
                name="name"
                className="input"
                type="text"
                placeholder="Name"
                onChange={this.handleChange}
                required
              />
            </div>
          </label>
        </div>
        <div className="field">
          <label className="label" htmlFor="email">
            Email Address
            <div className="control">
              <input
                id="email"
                name="email"
                className="input"
                type="email"
                placeholder="Email input"
                onChange={this.handleChange}
                required
              />
            </div>
          </label>
        </div>
        <div className="field">
          <label className="label" htmlFor="subject">
            Subject
            <div className="control">
              <input
                id="subject"
                name="subject"
                className="input"
                type="text"
                placeholder="Subject"
                onChange={this.handleChange}
                required
              />
            </div>
          </label>
        </div>
        <div className="field">
          <label className="label" htmlFor="message">
            Message
            <div className="control">
              <textarea
                id="message"
                name="message"
                className="textarea"
                placeholder="Your Message"
                onChange={this.handleMessageChange}
                style={{
                  outline: this.state.messageStatus ? `2px solid red` : `none`
                }}
              />
            </div>
          </label>
        </div>
        <Recaptcha
          className={this.state.recaptchaStatus}
          sitekey={RECAPTCHA_KEY}
          onChange={this.handleRecaptcha}
        />
        <br />
        <div className="field is-grouped">
          <div className="control">
            <button
              className="button is-black is-medium is-outlined"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    )
  }
}
